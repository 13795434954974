import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Mainsection from "src/components/section/main"
import Category from "src/components/faq/category"
import Faq from "src/components/faq/faq"

const IndexPage = ({ pageContext, data }) => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Mainsection title="よくあるご質問" subtitle="natadeCOCOに多く寄せられるご質問とその回答をご紹介します" />
      <Category category={pageContext.category}/>
      <Faq data={data} category={pageContext.category}/>
    </main>
    <Footer />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query faqQuery($category: String!) {
    allMarkdownRemark(
      filter: {frontmatter: {type: {eq: "faq"}, publish: {eq: true}, category: {eq: $category}}},
      sort: { fields: [frontmatter___sort], order: ASC },
    ) {
      edges {
        node {
          id
          frontmatter {
            category
            tag
            title
          }
          html
        }
      }
    }
  }
`
